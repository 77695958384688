.profile_section {
	width: 100%;
	height: auto;
	border-radius: 6px;
	padding: 30px;
}

.profile_section .subcontent {
	width: 100%;
	height: auto;
	margin: 0 auto;
}

.profile_section .section_photo {
	width: 50px;
	height: auto;
	float: left;
	margin-right: 20px;
}

.profile_section .section_photo .photo {
	width: 40px;
	height: auto;
	overflow: hidden;
	border-radius: 50%;
	margin-right: 10px;
}

.profile_section .section_info {
	width: 90%;
	height: auto;
	float: left;
	position: relative;
	top: 5px;
}

.profile_section .section_info .user_info {
	width: 100%;
	height: auto;
}

.profile_section .section_info .user_name {
	height: auto;
	float: left;
	margin-right: 10px;
	position: relative;
	top: 8px;
}

.profile_section .section_info .user_name span {
	color: #9aaadb !important;
	font-family: "DMSans-Regular" !important;
}

.profile_section .section_info .user_rol {
	width: 300px;
	height: auto;
	float: left;
	position: relative;
	top: 4px;
}

.profile_section .section_info .user_rol .icon {
	width: 24px;
	height: 24px;
	background-color: #22b07d;
	border-radius: 4px;
	color: #fff;
	text-align: center;
	float: left;
	margin-right: 10px;
	position: relative;
}

.profile_section .section_info .user_rol .icon svg {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
}

.profile_section .section_info .user_rol .rol {
	width: 100px;
	height: auto;
	float: left;
	position: relative;
	top: 3px;
}

.profile_section .section_info .user_edit {
	width: 24px;
	height: auto;
	float: right;
}

.profile_section .user_edit .icon {
	width: 24px;
	height: 24px;
	background-color: #22b07d;
	border-radius: 4px;
	color: #fff;
	text-align: center;
	float: left;
	margin-right: 10px;
	position: relative;
	cursor: pointer;
}

.profile_section .user_edit .icon svg {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
}

.profile_section .user_data {
	width: 100%;
	height: auto;
	padding-top: 10px;
	border-top: 1px solid #9aaadb;
	margin-top: 40px;
	padding-top: 30px;
	margin-bottom: 30px;
}

.profile_section .user_data {
	width: 100%;
	height: auto;
}

.profile_section .section_info .updateProfileBTN {
	width: 210px;
	height: auto;
	float: left;
	position: relative;
	border: 1px solid #9aaadb;
	padding: 6px;
	border-radius: 6px;
	cursor: pointer;
}

.profile_section .section_info .updateProfileBTN .icon {
	width: 24px;
	height: 24px;
	background-color: #9aaadb;
	border-radius: 4px;
	color: #fff;
	text-align: center;
	float: left;
	margin-right: 10px;
	position: relative;
	cursor: pointer;
}

.profile_section .section_info .updateProfileBTN .icon svg {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
}
