.psc__login {
	width: 100%;
	height: 100vh;
	z-index: 999;
	position: fixed;
}

.psc__login .block_images {
	width: 50%;
	height: 100vh;
	overflow: hidden;
	float: left;
	position: relative;
	z-index: 999;
}

.psc__login .block_form {
	width: 50%;
	height: 100vh;
	background: #212332;
	float: left;
	position: relative;
}

.background-image {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 1;
	transition: 3s;
	position: absolute;
}

.img1 {
	width: 100%;
	height: 100%;
	background: url(../images/login/imagen_1.jpg);
	background-repeat: no-repeat;
	background-position: 0 0;
	background-size: 100% 100%;
	z-index: -1;
}

.img2 {
	width: 100%;
	height: 100%;
	background: url(../images/login/imagen_2.jpg);
	background-repeat: no-repeat;
	background-position: 0 0;
	background-size: 100% 100%;
	z-index: -2;
}

.img3 {
	width: 100%;
	height: 100%;
	background: url(../images/login/imagen_3.jpg);
	background-repeat: no-repeat;
	background-position: 0 0;
	background-size: 100% 100%;
	z-index: -3;
}

.logo {
	width: 300px;
	height: auto;
	margin: 0 auto;
	margin-bottom: 120px;
}

.form_container {
	width: 70%;
	height: auto;
	margin: 0 auto;
}

.password-block {
	position: relative;
}

.password-block span {
	width: 10px;
	position: absolute;
	right: 20px;
	top: 10px;
	cursor: pointer;
}
