.psc__section_title {
	font-family: "DMSans-Bold";
	font-size: 22px;
	line-height: 30px;
}

.psc__section_subtitle_big {
	font-family: "Inter-Bold";
	font-size: 22px;
	line-height: 30px;
}

.psc__section_subtitle {
	font-family: "Inter-Bold";
	font-size: 16px;
	line-height: 16px;
}

.psc__text_normal {
	font-family: "Poppins-Regular";
	font-size: 14px;
	line-height: 21px;
}

.psc__menu_normal {
	font-family: "DMSans-Regular";
	font-size: 16px;
	line-height: 18px;
}
