.psc__dashboard {
	width: 100%;
	height: 100vh;
	background-color: #212332;
	position: relative;
	overflow: hidden;
}

.main-logo {
	width: 200px;
	height: auto;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
}

.main-logo p {
	width: 100%;
	height: auto;
	text-align: center;
	font-family: Inter-Regular;
	font-size: 14px;
	color: #9899a1;
}

.psc__content_section {
	width: 100%;
	height: auto;
	position: relative;
	padding-top: 50px;
	top: 0px;
	overflow-x: scroll;
}

.home__section_data {
	width: 100%;
	height: auto;
}

.home__section_data .header_section {
	width: 100%;
	height: auto;
	margin-bottom: 30px;
}
.home__section_data .btn__icon_date {
	float: left;
	left: 14px;
}

.home__section_data .select_filer_dates {
	width: 160px;
	height: auto;
	float: right;
}

.home__section_data .info_blocks {
	width: 100%;
	height: auto;
	margin-bottom: 60px;
}

.home__section_data .info_blocks .block {
	width: 25%;
	height: auto;
	float: left;
}
.home__section_data .info_blocks .block .data {
	width: 95%;
	height: 134px;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
	border-radius: 6px;
	position: relative;
	margin: 0 auto;
}

.home__section_data .info_blocks .block .data .info {
	width: 90%;
	height: auto;
}

.home__section_data .info_blocks .icon {
	width: 50px;
	height: 46px;
	border-radius: 4px;
	color: #fff;
	text-align: center;
	font-size: 32px;
	line-height: 32px;
	margin-right: 10px;
	position: relative;
	float: left;
	top: 4px;
}

.home__section_data .info_blocks .icon svg {
	position: relative;
	top: 7px;
}

.home__section_data .info_blocks .numbers {
	width: 70%;
	height: auto;
	float: left;
	position: relative;
	top: 8px;
}

.home__section_data .info_blocks .numbers .number {
	width: 100%;
	height: auto;
	font-size: 22px;
	line-height: 22px;
	font-family: "DMSans-Bold";
	margin-bottom: 5px;
}

.home__section_data .info_blocks .numbers .description {
	width: 100%;
	height: auto;
	font-size: 14px;
	line-height: 14px;
	font-family: "Poppins-Regular";
}

.tables_graphs_section {
	width: 100%;
	height: auto;
	margin-bottom: 60px;
}

.tables_graphs_section .companies_section {
	width: 50%;
	height: auto;
	float: left;
}

.tables_graphs_section .graphs_section {
	width: 50%;
	height: auto;
	float: left;
}

.tables_graphs_section .graphs_section .graphs_container {
	width: 90%;
	height: auto;
	margin-left: auto;
	padding: 20px;
	border-radius: 6px;
}

.header_company {
	width: 100%;
	height: auto;
	margin-bottom: 30px;
}

.header_company .title_company {
	width: 120px;
	height: auto;
	float: left;
}

.header_company .select_filer_companies {
	width: 160px;
	height: auto;
	float: right;
}

.header_company .subtitle_company {
	width: 50%;
	height: auto;
	float: left;
}

.line {
	width: 100%;
	height: 1px;
	background-color: #95abdf;
}

.company_list_Section {
	width: 100%;
	height: auto;
}

.company_list_Section::-webkit-scrollbar-track {
	background: #cfd8dc;
}

.company_list_Section .list_company {
	width: 100%;
	height: auto;
	border-radius: 6px;
	background-color: #2c2e3c;
	margin-bottom: 10px;
	padding: 10px;
}

.company_list_Section .list_company .icon {
	width: 40px;
	height: 40px;
	background-color: #fff;
	border-radius: 6px;
	overflow: hidden;
	float: left;
}

.company_list_Section .list_company .data_seals {
	width: 85%;
	height: auto;
	float: left;
	position: relative;
	left: 10px;
	top: 10px;
}

.company_list_Section .list_company .data_seals .company_name {
	width: 60%;
	height: auto;
	float: left;
}

.company_list_Section .list_company .data_seals .company_seals {
	width: 30%;
	height: auto;
	text-align: right;
	float: right;
	font-family: "Poppins-SemiBold";
}

.graphs_section .graphs_header {
	width: 100%;
	height: auto;
}

.graphs_section .graphs_header .title_company {
	width: 180px;
	font-size: 18px;
	line-height: 24px;
	position: relative;
	top: 8px;
}

.graps_data_info {
	width: 100%;
	height: auto;
	margin-bottom: 30px;
}

.graps_data_info .data_seal_option {
	width: 70%;
	height: auto;
	margin-bottom: 20px;
}

.graps_data_info .data_seal_option .description {
	width: 100px;
	height: auto;
	float: left;
	padding-left: 10px;
	position: relative;
}

.graps_data_info .data_seal_option .description_1::after {
	content: "";
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: #212332;
	position: absolute;
	left: -5px;
	top: 7px;
}

.graps_data_info .data_seal_option .description_2::after {
	content: "";
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: #22b07d;
	position: absolute;
	left: -5px;
	top: 7px;
}

.graps_data_info .data_seal_option .number {
	width: 75px;
	height: auto;
	float: right;
	text-align: right;
}

.graph_circle_block {
	width: 300px;
	height: auto;
	margin: 0 auto;
}
