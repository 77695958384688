.section_seals_details {
	width: 100%;
	height: auto;
	padding: 20px;
	border-radius: 6px;
}

.section_seals_details .title {
	width: 100%;
	height: auto;
	margin-bottom: 10px;
}

.section_seals_details .title_last {
	margin-bottom: 30px;
}

.section_seals_details .seal_id {
	width: 100%;
	height: auto;
	margin-bottom: 40px;
}

.section_seals_details .status_Data {
	width: 100%;
	height: auto;
	margin-bottom: 0px;
}

.section_seals_details .status_Data .title span {
	color: #9899a1 !important;
	position: relative;
	margin-left: 10px;
	display: inline;
}

.section_seals_details .status_Data .title .iconSection {
	width: 36px;
	height: 36px;
	border-radius: 6px;
	float: left;
	text-align: center;
}

.t_span_text {
	position: relative;
	top: 10px;
}
.section_seals_details .status_Data .title svg {
	font-size: 22px;
	position: relative;
	top: 7px;
}

.section_seals_details .revisions_container {
	width: 100%;
	height: auto;
	max-height: 400px;
	margin-bottom: 20px;
	overflow-y: scroll;
	padding-right: 10px;
}

.section_seals_details .noScroll {
	width: 100% !important;
	height: auto !important;
	margin-bottom: 20px !;
	overflow-y: auto !important;
}

.section_seals_details .revison_block {
	width: 100%;
	height: 56px;
	background: #2a2d3e;
	border-radius: 6px;
	padding: 20px 20px 20px 20px;
	margin-bottom: 10px;
	cursor: pointer;
}

.section_seals_details .revison_block .info {
	width: 33.33%;
	height: auto;
	float: left;
	font-family: "Inter-Regular";
	font-size: 16px;
	line-height: 16px;
}

.section_seals_details .revison_block .info_b {
	width: 100%;
	height: auto;
	float: left;
	font-family: "Inter-Regular";
	font-size: 16px;
	line-height: 16px;
}
.section_seals_details .title_revision_no {
	position: relative;
	top: -7px;
}

.section_seals_details .revisions_container svg {
	font-size: 22px;
	position: relative;
	top: 4px;
	margin-left: 5px;
	margin-right: 5px;
}

.section_seals_details .captures_container {
	width: 100%;
	height: auto;
	margin-bottom: 30px;
}
.section_seals_details .captures_container .ant-image {
	margin-right: 10px;
}

.ant-image-preview-img {
	width: auto;
}
