.psc__header {
	width: 90%;
	height: auto;
	margin: 0 auto;
	margin-top: 30px;
	margin-bottom: 60px;
}

.psc__header input {
	width: 80%;
	height: 50px;
	border: 1px solid rgba(0, 0, 0, 0);
	background: transparent;
	margin: 0 auto;
	border-radius: 5px;
	color: #9899a1 !important;
	padding-left: 40px;
	font-size: 16px;
	background-color: #2a2d3e;
}

.psc__header input:focus {
	outline: none;
	border: 1px solid #339dff !important;
}

.psc__header .InputSearch {
	width: 60%;
	height: auto;
	position: relative;
	float: left;
}

.psc__header .Profile {
	width: 20%;
	height: auto;
	float: right;
	position: relative;
	top: 5px;
}

.psc__header .Profile .notify {
	width: 32px;
	height: auto;
	background-color: #2a2d3e;
	border-radius: 4px;
	color: #fff;
	text-align: center;
	padding: 6px;
	font-size: 16px;
	line-height: 16px;
	float: right;
	margin-left: 20px;
	position: relative;
	top: 8px;
}

.psc__header .Profile .profilePicture {
	width: 100%;
	height: auto;
}

.psc__header .Profile .profilePicture .photo {
	width: 40px;
	height: auto;
	float: right;
	overflow: hidden;
	border-radius: 50%;
	margin-right: 10px;
}

.psc__header .Profile .profilePicture .info {
	width: 100px;
	height: auto;
	float: right;
	font-size: 12px;
	line-height: 16px;
	color: #fff;
	position: relative;
	top: 5px;
	text-align: right;
}

.psc__header .Profile .profilePicture .info .title {
	width: 100%;
	height: auto;
	font-family: "Inter-Bold";
}

.psc__header .Profile .profilePicture .info .role {
	width: 100%;
	height: auto;
	font-family: "Inter-Regular";
}

.pcs__center_content {
	width: 95%;
	height: auto;
	margin: 0 auto;
	position: relative;
}

.pcs__center_content .section_title {
	width: 100%;
	height: auto;
	margin: 0 auto;
	margin-bottom: 30px;
}

.pcs__center_content .section_seals_data {
	width: 70%;
	height: auto;
	color: #fff;
	margin-bottom: 30px;
	color: #9899a1;
	font-size: 16px;
}

.pcs__center_content .section_seals_data .block {
	width: 33.33%;
	height: auto;
	float: left;
}

.pcs__center_content .section_seals_data .block .icon {
	width: 34px;
	height: auto;
	border-radius: 4px;
	color: #fff;
	text-align: center;
	padding: 6px;
	font-size: 22px;
	line-height: 16px;
	float: left;
	margin-right: 15px;
	position: relative;
	top: 0px;
	float: left;
}

.pcs__center_content .section_seals_data .block .info {
	width: 180px;
	height: auto;
	float: left;
	position: relative;
	top: 6px;
}

.section_seals_table {
	width: 100%;
	height: auto;
	margin-bottom: 30px;
}

.section_seals_table .data_tables {
	width: 100%;
	height: auto;
	padding: 20px;
	border-radius: 6px;
}

.section_seals_table .data_tables .header {
	width: 100%;
	height: auto;
	margin-bottom: 30px;
}

.section_seals_table .data_tables .header .title {
	width: 30%;
	height: auto;
	float: left;
}

.section_seals_table .data_tables .header .filter {
	width: 100px;
	height: auto;
	color: #fff;
	font-family: "Inter-Light";
	font-size: 14px;
	float: right;
	position: relative;
	top: 3px;
	cursor: pointer;
}

.section_seals_table .data_tables .header {
	width: 100%;
	height: auto;
	margin-bottom: 30px;
}

.header .search_form_block {
	width: 260px;
	height: auto;
	position: relative;
	float: left;
}

.header .options {
	width: 500px;
	height: auto;
	position: relative;
	float: right;
	border: 1px solid #339dff;
	border-radius: 6px;
	margin-bottom: 0px;
}

.header .options .btns {
	width: 33.33%;
	height: auto;
	padding-top: 8px;
	padding-bottom: 8px;
	position: relative;
	float: left;
	text-align: center;
	color: #ffffff;
	cursor: pointer;
}

.header .options_disabled {
	width: 500px;
	height: auto;
	position: relative;
	float: right;
	border: 1px solid #2a2d3e;
	border-radius: 6px;
	margin-bottom: 0px;
}

.header .options .btns_disabled {
	width: 33.33%;
	height: auto;
	padding-top: 8px;
	padding-bottom: 8px;
	position: relative;
	float: left;
	text-align: center;
	background-color: #dddddd;
	color: #9899a1;
	cursor: pointer;
}

.btn_border_r {
	border-right: 1px solid #339dff;
}

.btn_border_r_disabled {
	border-right: 1px solid #9899a1;
}

.header .options .btns:hover {
	background-color: #339dff;
	cursor: pointer;
}

.header .options .btns_disabled:hover {
	background-color: #dddddd;
	cursor: pointer;
}

.section_seals_table .ant-table-content {
	background-color: #212332 !important;
	color: #9899a1 !important;
}

.section_seals_table th {
	background-color: #212332 !important;
	color: #fff !important;
	font-family: "Inter-Bold";
}

.section_seals_table tr:hover .iconA {
	color: #ffffff !important;
}

.section_seals_table td.ant-table-cell-row-hover {
	background-color: #339dff !important;
	color: #fff !important;
}

.ant-table-tbody > tr > td {
	border-bottom: 1px solid #95abdf;
}

.ant-table-thead > tr > th {
	border-bottom: 1px solid #95abdf;
}

.ant-pagination-item-active a {
	background-color: #00649A !important;
	color: #fff !important;
}

.ant-pagination-item-active {
	border-color: #2a2d3e !important;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
	background-color: #2a2d3e !important;
	border: 1px solid #2a2d3e;
	color: #9899a1;
}

.SealID {
	cursor: pointer;
}

.s_success {
	background: #2ecc71;
	color: #fff;
}

.s_assign {
	background: #00649A;
	color: #fff;
}

.s_block {
	background: #e74c3c;
	color: #fff;
}

.s_unassign {
	border: solid 1px #74b9ff;
}

.s_transit {
	background: #74b9ff;
	color: #fff;
}

.s_status {
	border-radius: 20px;
	width: 100px;
	text-align: center;
}

.s_type {
	border-radius: 20px;
	width: 100px;
	text-align: center;
}

.s_ocr {
	width: 120px;
	text-align: center;
}

.s_type_1 {
	border: solid 1px #74b9ff;
	color: #74b9ff;
}
.s_type_2 {
	border: solid 1px #22b07d;
	color: #22b07d;
}
.s_type_3 {
	border: solid 1px #00649A;
	color: #00649A;
}

.users_status {
	border-radius: 20px;
	width: 100px;
	text-align: center;
}

.user_active {
	background: #2ecc71;
	color: #fff;
}

.user_block {
	background: #e74c3c;
	color: #fff;
}

.user_disable {
	background: #b2bec3;
	color: #636e72;
}

.rols_status {
	border-radius: 20px;
	width: 110px;
	text-align: center;
}

.rol_admin {
	border: solid 1px #22b07d;
	color: #22b07d;
}
.rol_capt {
	border: solid 1px #74b9ff;
	color: #74b9ff;
}
.rol_checker {
	border: solid 1px #00649A;
	color: #00649A;
}
