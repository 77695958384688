.Reports__section{
    width: 90%;
    height: auto;
    margin: 0 auto;
    
    
}
.Reports__section label{
    font-size: 12px;
    color: #9899a1;
    margin-bottom: 5px;
    display: block;
}

.Reports__section select {
    width: 100%;
    height: 40px;
    border: 1px solid #9899a1;
    background: transparent;
    margin: 0 auto;
    margin-bottom: 30px;
    border-radius: 5px;
    color: #9899a1 !important;
    font-size: 14px;
    padding-left: 10px;

}

.Reports__section .ant-picker{
        width:100%;
        margin-bottom: 20px;
        height: 40px;
        border: 1px solid #9899a1 !important;
        border-radius: 6px; 
}


.Reports__section .report__button__container{
    width: 420px;
    height: auto;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
}



.Reports__section .report__button{
    width: 200px;
    height: auto;
    text-align: center;
    background-color: #fff;
    color: #339dff;
    border: 1px solid #339dff;
    cursor: pointer;
    font-family: inter-bold;
    font-size: 14px;
    padding: 10px;
    border-radius: 6px;
    display: block;
    margin-bottom: 10px;
}

.Reports__section .report__button__false{
    width: 200px;
    height: auto;
    text-align: center;
    background-color: #fff;
    color: #9899a1;
    border: 1px solid #9899a1;
    cursor: pointer;
    font-family: inter-bold;
    font-size: 14px;
    padding: 10px;
    border-radius: 6px;
    display: block;
    margin-bottom: 10px;
}

.Reports__section .report__button:hover{
	background-color: #339dff;
	border: 1px solid #339dff;
    color: #fff;
}


.Reports__section .report__button--cancel{
    width: 200px;
    height: auto;
    text-align: center;
    background-color: #fff;
    color: #00649A;
    border: 1px solid #00649A;
    cursor: pointer;
    font-family: inter-bold;
    font-size: 14px;
    padding: 10px;
    border-radius: 6px;
    display: block;
    margin-bottom: 10px;
}

.Reports__section .report__button--cancel:hover{
	background-color: #00649A;
	border: 1px solid #00649A;
    color: #fff;
}
