/* --------------------------------------------------------
/* ---- Fonts
-------------------------------------------------------- */

@font-face {
	font-family: "Inter-Regular";
	src: url("../fonts/Inter/Inter-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Inter-Medium";
	src: url("../fonts/Inter/Inter-Medium.ttf") format("truetype");
}

@font-face {
	font-family: "Inter-Light";
	src: url("../fonts/Inter/Inter-Light.ttf") format("truetype");
}

@font-face {
	font-family: "Inter-Bold";
	src: url("../fonts/Inter/Inter-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "Inter-Black";
	src: url("../fonts/Inter/Inter-Black.ttf") format("truetype");
}

@font-face {
	font-family: "Poppins-Black";
	src: url("../fonts/Poppins/Poppins-Black.ttf") format("truetype");
}

@font-face {
	font-family: "Poppins-Bold";
	src: url("../fonts/Poppins/Poppins-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "Poppins-ExtraLight";
	src: url("../fonts/Poppins/Poppins-ExtraLight.ttf") format("truetype");
}

@font-face {
	font-family: "Poppins-Light";
	src: url("../fonts/Poppins/Poppins-Light.ttf") format("truetype");
}

@font-face {
	font-family: "Poppins-Medium";
	src: url("../fonts/Poppins/Poppins-Medium.ttf") format("truetype");
}

@font-face {
	font-family: "Poppins-Regular";
	src: url("../fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Poppins-SemiBold";
	src: url("../fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
	font-family: "DMSans-Bold";
	src: url("../fonts/DM_Sans/DMSans-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "DMSans-Medium";
	src: url("../fonts/DM_Sans/DMSans-Medium.ttf") format("truetype");
}

@font-face {
	font-family: "DMSans-Regular";
	src: url("../fonts/DM_Sans/DMSans-Regular.ttf") format("truetype");
}
