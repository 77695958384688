.btn__normal {
	width: 200px;
	height: auto;
	text-align: center;
	cursor: pointer;
	font-family: inter-bold;
	font-size: 14px;
	padding: 10px;
	border-radius: 6px !important;
}

.btn__normal_add {
	width: 160px;
	height: auto;
	text-align: right;
	cursor: pointer;
	font-family: inter-bold;
	font-size: 14px;
	padding: 10px 14px 10px 10px;
	border-radius: 6px;
	position: relative;
}

.btn__normal_add .iconAdd {
	width: 25px;
	height: 25px;
	position: absolute;
	border-radius: 6px;
	top: 8px;
}

.btn__normal_add .iconAdd svg {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
}

.btn__blue {
	background-color: #ffffff !important;
	color: #339dff !important;
	border: 1px solid #339dff !important;
}

.btn__blue:hover {
	background-color: #339dff !important;
	color: #ffffff !important;
	border: 1px solid #339dff !important;
}

.btn__orange {
	background-color: #ffffff !important;
	color: #00649A !important;
	border: 1px solid #00649A !important;
}

.btn__orange:hover {
	background-color: #00649A !important;
	color: #ffffff !important;
	border: 1px solid #00649A !important;
}

.btn__blue_2 {
	background-color: #339dff !important;
	color: #ffffff !important;
	border: 1px solid #339dff !important;
}

.btn__blue_2:hover {
	background-color: #ffffff !important;
	color: #339dff !important;
	border: 1px solid #339dff !important;
}

.btn__orange_2 {
	background-color: #00649A !important;
	color: #ffffff !important;
	border: 1px solid #00649A !important;
}

.btn__orange_2:hover {
	background-color: #ffffff !important;
	color: #00649A !important;
	border: 1px solid #00649A !important;
}

.btn__white {
	color: #fff;
	border: 1px solid #339dff;
	border-radius: 6px;
}

.btn__white .iconAdd {
	background-color: #339dff;
	color: #ffffff;
}

.btn__white:hover {
	background-color: #339dff;
	color: #ffffff;
	border: 1px solid #339dff;
}

.btn__back {
	width: 142px;
	height: auto;
	text-align: center;
	cursor: pointer;
	font-family: inter-bold;
	font-size: 16px;
	padding: 10px;
	border-radius: 6px;
	position: relative;
}

.btn__back::before {
	content: "";
	width: 34px;
	height: 34px;
	color: #ffffff;
	border: 1px solid #339dff;
	border-radius: 6px;
	position: absolute;
	top: 5px;
	left: -5px;
	background: url(../images/icons/back-arrow-white.png);
	background-color: #339dff;
	background-size: 15px 15px;
	background-position: center;
	background-repeat: no-repeat;
}

.btn__back:hover::before {
	content: "";
	width: 34px;
	height: 34px;
	color: #00649A;
	border: 1px solid #ffffff;
	border-radius: 6px;
	position: absolute;
	top: 5px;
	left: -5px;
	background: url(../images/icons/back-arrow-orange.png);
	background-color: #ffffff;
	background-size: 15px 15px;
	background-position: center;
	background-repeat: no-repeat;
}

.btn__icon_date {
	width: 300px;
	height: auto;
	cursor: pointer;
	font-family: inter-Regular;
	font-size: 16px;
	padding: 10px 10px 10px 30px;
	border-radius: 6px;
	position: relative;
}
.btn__icon_date::before {
	content: "";
	width: 34px;
	height: 34px;
	color: #ffffff;
	border: 1px solid #339dff;
	border-radius: 6px;
	position: absolute;
	top: 5px;
	left: -10px;
	background: url(../images/icons/calendar.png);
	background-color: #339dff;
	background-size: 20px 20px;
	background-position: center;
	background-repeat: no-repeat;
}

.btn__icon_type_2 {
	height: auto;
	float: left;
	position: relative;
	padding: 6px;
	border-radius: 6px;
	cursor: pointer;
}

.btn__icon_type_2 .title {
	position: relative;
	top: 2px;
}

.btn__icon_type_2 .icon {
	width: 24px;
	height: 24px;
	border-radius: 4px;
	text-align: center;
	float: left;
	margin-right: 10px;
	position: relative;
	cursor: pointer;
}

.btn__purple {
	border: 1px solid #9aaadb;
}
.btn__purple .icon {
	background-color: #9aaadb;
	color: #fff;
}

.btn__icon_type_2 .icon svg {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
}

.btn_modal_content {
	width: 420px;
	margin: 0 auto;
}


.btn__container{
	width: 360px;
	height: auto;
	display: flex;
	justify-content: space-between;
}

.btn--big{
	width: 170px;
}