.psc__form_login input {
	width: 100%;
	height: 40px;
	border: 1px solid #9899a1;
	background: transparent;
	margin: 0 auto;
	margin-bottom: 30px;
	border-radius: 5px;
}

.psc__form_login input:focus {
	outline: none;
	border: 1px solid #339dff !important;
}
.psc__form_login input {
	color: #9899a1 !important;
	padding-left: 20px;
	font-size: 14px;
}

.psc__form_login input::placeholder {
	color: #9899a1 !important;
	font-size: 14px;
}

.psc__form_login input[type="submit"] {
	background-color: #339dff;
	border: 1px solid #339dff;
	color: #fff !important;
	cursor: pointer;
	font-family: inter-bold;
	font-size: 14px;
}

.psc__form_login input[type="submit"]:hover {
	background-color: #00649A;
	border: 1px solid #00649A;
	color: #fff;
	cursor: pointer;
}

.psc__form_login span {
	width: 100%;
	height: auto;
	font-size: 14px;
	margin-bottom: 20px;
	position: relative;
	color: #9899a1;
	display: block;
}

.psc__form_login a {
	font-family: inter-bold;
	color: #fff;
	text-decoration: none;
}
.psc__form_login a:hover {
	color: #00649A;
}

.psc__form_login .f_error {
	width: 100%;
	height: auto;
	font-family: Inter-Regular;
	font-size: 14px;
	color: #ff3333;
	position: relative;
	top: -15px;
}

.errorMessage {
	width: 100%;
	height: 60px;
	background: #2a2d3e;
	margin-bottom: 22px;
	border-radius: 5px;
	position: relative;
	padding: 10px;
}

.icon-warning {
	width: 30px;
	height: 30px;
	background: url("../images/icons/icon-warning.png");
	background-size: 30px 30px;
}

.errorMessage .errorData {
	width: 100%;
	height: auto;
	color: #9899a1;
	position: relative;
	top: 3px;
}

.errorMessage .errorData .icon {
	width: 40px;
	height: auto;
	float: left;
	position: relative;
	top: 2px;
}

.errorMessage .errorData .info {
	width: 80%;
	height: auto;
	float: left;
}

.errorMessage .errorData .info .message {
	width: 100%;
	height: auto;
	font-family: Inter-Bold;
	font-size: 16px;
	line-height: 18px;
}

.errorMessage .errorData .info .actions {
	width: 100%;
	height: auto;
	font-family: Inter-Regular;
	font-size: 14px;
}

.PSC__Search {
	position: relative;
}

.PSC__Search .icon {
	color: #9899a1;
	font-size: 22px;
	position: absolute;
	left: 12px;
	top: 58%;
	transform: translate(0%, -50%);
	-webkit-transform: translate(0%, -50%);
}

.PSC__Search input:focus {
	outline: none;
	border: 1px solid #339dff !important;
}
.PSC__Search input {
	padding-left: 40px;
	font-size: 14px;
	background: transparent;
	margin: 0 auto;
	border-radius: 5px;
	font-family: "Poppins-Regular";
}

.PSC__Search input::placeholder {
	color: #9899a1 !important;
	font-size: 14px;
}

.PSC__form_50 {
	width: 50%;
	height: auto;
	float: left;
}

.PSC__form_50 .form_content {
	width: 90%;
	height: auto;
}

.PSC__form_100 {
	width: 100%;
	height: auto;
}

.passwordInput {
	width: 100%;
	height: 40px;
	position: relative;
}

.passwordInput .eye {
	width: 20px;
	height: 20px;
	position: absolute;
	top: 10px;
	right: 10px;
	color: #9899a1;
}
