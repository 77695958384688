.bg-sky-blue {
	background-color: #339dff !important;
}

.bg-green-bull {
	background-color: #22b07d !important;
}

.bg-orange {
	background-color: #ef8727 !important;
}

.bg-dark-blue-primary {
	background-color: #212332 !important;
}

.bg-dark-blue-secondary {
	background-color: #2a2d3e !important;
}

.bg-purple {
	background-color: #9aaadb !important;
}

.bg-green {
	background-color: #54ad81 !important;
}

.bg-red {
	background-color: #e74c3c;
}

.text-white {
	color: #ffffff;
}

.text-purple {
	color: #9aaadb !important;
}

.text-gray {
	color: #9899a1 !important;
}

.text-black {
	color: #000000 !important;
}

.text-sky-blue {
	color: #339dff !important;
}

.text-orange {
	color: #ef8727 !important;
}
