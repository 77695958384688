.psc__MainMenu {
	width: 283px;
	height: 100vh;
	position: fixed;
	display: block;
	overflow: hidden;
}

.psc__MainMenu .logo {
	width: 180px;
	height: auto;
	margin: 0 auto;
	margin-top: 20px;
	margin-bottom: 40px;
}

.psc__MainMenu .menuItemsBlock {
	width: 70%;
	height: auto;
	margin: 0 auto;
}

.psc__MainMenu .menuItemsBlock .items {
	height: auto;
	margin-bottom: 10px;
	border-radius: 10px;
	color: #9899a1;
	position: relative;
	cursor: pointer;
}

.psc__MainMenu .menuItemsBlock .active {
	height: auto;
	background-color: #5db1ff;
	color: #fff !important;
	margin-bottom: 10px;
	border-radius: 10px;
	position: relative;
}

.M-icon {
	top: 3px;
	font-size: 20px !important;
	position: relative;
	left: -4px;
}
.psc__MainMenu .menuItemsBlock .items .name {
	padding: 12px 0px 16px 20px;
}

.psc__MainMenu .menuItemsBlock .items:hover {
	background-color: #5db1ff;
	color: #fff !important;
}

.psc__MainMenu .menuItemsBlock .items:hover .name {
	color: #fff !important;
}

.psc__MainMenu .menuItemsBlock .submenu {
	height: auto;
	margin-bottom: 15px;
	border-radius: 10px;
	color: #9899a1;
	position: relative;
	cursor: pointer;
	margin-left: 30px;
}

.psc__MainMenu .menuItemsBlock .submenu {
	font-family: "Inter-Medium";
	font-size: 16px;
	padding: 16px 0px 0px 0px;
}

.psc__MainMenu .menuItemsBlock .submenu:hover {
	color: #fff !important;
}

.mb__menu_item {
	display: flex;
	flex-direction: row;
	/* justify-content: center; */
	align-items: center;
	padding: 10px;
}
.mb__menu_item_text {
	line-height: 1;
	font-family: "Poppins-Medium";
	font-size: 14;
	padding: 0px 10px;
}
.mb__menu_item_icon {
	padding: 5px 5px;
}
