.ant-modal-footer {
	display: block !important;
}

.psc__modal_btn_content {
	width: 430px;
	height: auto;
	margin: 0 auto;
	margin-bottom: 20px;
}

.psc__modal_btn_content_center {
	width: 100%;
	height: auto;
	margin: 0 auto;
	margin-bottom: 20px;
}

.psc__modal_info {
	width: 100%;
	height: auto;
	margin-bottom: 30px;
}

.psc__modal_btn_content .btn__normal {
	float: left;
	margin-right: 10px;
}

.psc__modal_btn_content .info {
	width: 100%;
	height: auto;
	margin-bottom: 20px;
}

.psc__modal_btn_content_center .btn__normal {
	float: none;
	margin: 0 auto;
}

.ant-modal-content {
	border-radius: 6px !important;
	font-family: "Inter-Regular";
	font-size: 14px;
}

.ant-modal-header {
	border-radius: 6px !important;
}

.ant-modal-title {
	font-family: "Inter-Bold";
	font-size: 18px;
}

.psc__form_modal label {
	font-size: 12px;
	color: #9899a1;
	margin-bottom: 5px;
	display: block;
}

.psc__form_modal input {
	width: 100%;
	height: 40px;
	border: 1px solid #9899a1;
	background: transparent;
	margin: 0 auto;
	margin-bottom: 10px;
	border-radius: 5px;
}
.psc__form_modal input:focus {
	outline: none;
	border: 1px solid #339dff !important;
}
.psc__form_modal select {
	width: 100%;
	height: 40px;
	border: 1px solid #9899a1;
	background: transparent;
	margin: 0 auto;
	margin-bottom: 20px;
	border-radius: 5px;
	color: #9899a1 !important;
	font-size: 14px;
	padding-left: 10px;
}
.psc__form_modal select:focus {
	outline: none;
	border: 1px solid #339dff !important;
}

.psc__form_modal .f_error {
	width: 100%;
	height: auto;
	font-family: Inter-Regular;
	font-size: 12px;
	color: #ff3333;
	position: relative;
	text-align: center;
	margin-bottom: 0px;
}

.psc__form_modal input {
	color: #9899a1 !important;
	padding-left: 20px;
	font-size: 14px;
}

.psc__form_modal input[type="submit"] {
	width: 200px;
	height: auto;
	text-align: center;
	background-color: #fff;
	color: #339dff !important;
	border: 1px solid #339dff;
	cursor: pointer;
	font-family: inter-bold;
	font-size: 14px;
	padding: 10px;
	border-radius: 6px;
	display: block;
	margin-bottom: 10px;
}

.psc__form_modal input[type="submit"]:hover {
	width: 200px;
	height: auto;
	text-align: center;
	background-color: #339dff;
	color: #fff !important;
	border: 1px solid #339dff;
	cursor: pointer;
	font-family: inter-bold;
	font-size: 14px;
	padding: 10px;
	border-radius: 6px;
}

.psc__form_modal_update input[type="submit"] {
	width: 200px;
	height: auto;
	text-align: center;
	background-color: #339dff !important;
	color: #fff !important;
	border: 1px solid #339dff !important;
	cursor: pointer;
	font-family: inter-bold;
	font-size: 14px;
	padding: 10px;
	border-radius: 6px;
	display: block;
	margin-bottom: 10px;
}

.psc__form_modal_update input[type="submit"]:hover {
	width: 200px;
	height: auto;
	text-align: center;
	background-color: #fff !important;
	color: #339dff !important;
	border: 1px solid #339dff !important;
	cursor: pointer;
	font-family: inter-bold;
	font-size: 14px;
	padding: 10px;
	border-radius: 6px;
}

.psc__btn_blue_input input[type="submit"] {
	width: 200px;
	height: auto;
	text-align: center;
	background-color: #339dff !important;
	color: #fff !important;
	border: 1px solid #339dff !important;
	cursor: pointer;
	font-family: inter-bold;
	font-size: 14px;
	padding: 10px;
	border-radius: 6px;
	display: block;
	margin-bottom: 10px;
}

.psc__btn_blue_input input[type="submit"]:hover {
	width: 200px;
	height: auto;
	text-align: center;
	background-color: #fff !important;
	color: #339dff !important;
	border: 1px solid #339dff !important;
	cursor: pointer;
	font-family: inter-bold;
	font-size: 14px;
	padding: 10px;
	border-radius: 6px;
}

.drag-content {
	width: 100%;
	height: auto;
	margin-bottom: 30px !important;
}

.ant-upload-text {
	font-family: Inter-Regular !important;
	font-size: 16px !important;
	color: #9899a1 !important;
}

.ant-upload-drag-icon {
	font-size: 30px;
	line-height: 30px;
}

.ant-upload-list-item {
	margin-bottom: 20px;
}
