.ant-layout-sider {
	flex: 0 0 283px !important;
	max-width: 283px !important;
	min-width: 283px !important;
	width: 283px !important;
}

.ant-empty-description {
	color: #fff !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	position: relative;
	background-color: #2a2d3e !important;
	border: 1px solid #95abdf !important;
	border-radius: 6px !important;
	color: #9899a1 !important;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-select-arrow {
	color: #9899a1 !important;
}

td.ant-table-column-sort {
	background: #212332 !important;
}
