body {
	background-color: #212332 !important;
}

.transition {
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
}
.clear {
	clear: both;
}

.mr-10 {
	margin-right: 10px !important;
}
.mb-10 {
	margin-bottom: 10px !important;
}

.mb-20 {
	margin-bottom: 20px !important;
}

.mb-30 {
	margin-bottom: 30px !important;
}

.mb-40 {
	margin-bottom: 40px !important;
}

.mb-50 {
	margin-bottom: 50px !important;
}

.mb-60 {
	margin-bottom: 60px;
}

.mb-70 {
	margin-bottom: 70px;
}

.text-center {
	text-align: center;
}
.text-left {
	text-align: left;
}
.text-right {
	text-align: right;
}

.pointer {
	cursor: pointer;
}

.f-left {
	float: left;
}
